import { render, staticRenderFns } from "./dis_overview.vue?vue&type=template&id=45713b23&scoped=true"
import script from "./dis_overview.vue?vue&type=script&lang=js"
export * from "./dis_overview.vue?vue&type=script&lang=js"
import style0 from "./dis_overview.vue?vue&type=style&index=0&id=45713b23&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45713b23",
  null
  
)

export default component.exports